const Iframe = () => {
  const calltoActionContent = {
    title: "Forma parte del club",
    text: "Cuando obtienes uno de nuestros NFTs, te estás haciendo socio de un club cuyos beneficios, ventajas y ofertas aumentarán con el paso tiempo. Tu Digital Wines Club NFT será tu identidad digital que te permitirá abrir infinidad de puertas digitales.",
    btnText: "Consigue tu membresía",
  };


  return (
    <div className="container">
      <div className="relative z-10 overflow-hidden rounded-2.5xl px-16 py-24 lg:px-24">
        <iframe
            src="https://embed.ipfscdn.io/ipfs/bafybeicd3qfzelz4su7ng6n523virdsgobrc5pcbarhwqv3dj3drh645pi/?contract=0xC43F7EF994Ce2cCCc02510d8DcAe5354bC6E2203&chain=%7B%22name%22%3A%22Polygon+Mainnet%22%2C%22chain%22%3A%22Polygon%22%2C%22rpc%22%3A%5B%22https%3A%2F%2F137.rpc.thirdweb.com%2F%24%7BTHIRDWEB_API_KEY%7D%22%5D%2C%22nativeCurrency%22%3A%7B%22name%22%3A%22MATIC%22%2C%22symbol%22%3A%22MATIC%22%2C%22decimals%22%3A18%7D%2C%22shortName%22%3A%22matic%22%2C%22chainId%22%3A137%2C%22testnet%22%3Afalse%2C%22slug%22%3A%22polygon%22%2C%22icon%22%3A%7B%22url%22%3A%22ipfs%3A%2F%2FQmcxZHpyJa8T4i63xqjPYrZ6tKrt55tZJpbXcjSDKuKaf9%2Fpolygon%2F512.png%22%2C%22width%22%3A512%2C%22height%22%3A512%2C%22format%22%3A%22png%22%7D%7D&clientId=c3d70e48c58f41111027cf358f4c9dbf&theme=light&primaryColor=purple"
            width="100%"
            height="600px"
            title="Polygon Contract Widget"
          ></iframe>
        </div>
      </div>
  );
};

export default Iframe;
